import React, { useEffect, useState } from 'react';
import { Code, MonitorPlayIcon, Cog, Wrench, ArrowBigUpDash, Instagram } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import ASCIIText from './ASCIIText';

const deployedAt = process.env.REACT_APP_DEPLOY_TIME;

const Portfolio = () => {
  const [scrollY, setScrollY] = useState(0);
  const [showArrow, setShowArrow] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

 useEffect(() => {
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

   // Adding scroll event listener
  window.addEventListener('scroll', handleScroll);

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  // Nastavíme čas, kdy byla stránka načtena
  const currentTime = new Date().toLocaleString(); // Tento formát vrátí čas v lokalizovaném formátu
  setLastUpdated(currentTime);
}, []); // Prázdné pole znamená, že useEffect se spustí pouze při prvním vykreslení

  const skills = [
    {
      title: "Multimedia",
      icon: <MonitorPlayIcon className="w-12 h-12 mb-4 text-blue-500 animate-[bounce_2s_ease-in-out_infinite]" />,
      description: "Video creation and editing, photo editing, and graphic material design. Working with professional tools for multimedia processing."
    },
    {
      title: "Programming",
      icon: <Code className="w-12 h-12 mb-4 text-green-500 animate-[pulse_1s_ease-in-out_infinite]" />,
      description: "Development of web applications, knowledge of various programming languages, and modern frameworks."
    },
    {
      title: "Software Installation",
      icon: <Cog className="w-12 h-12 mb-4 text-purple-500 animate-[spin_1s_linear_infinite]" />,
      description: "Professional software installation and configuration, system administration, and troubleshooting technical issues."
    },
    {
      title: "Robotics",
      icon: <Wrench className="w-12 h-12 mb-4 text-red-500 animate-[rotate90_2s_ease-in-out_infinite]" />,
      description: "Design and programming of robotic systems, working with microcontrollers, and automation."
    }
  ];

  const gradientStyle = {
    backgroundPosition: `center ${Math.min(scrollY / 3, 100)}%`,
    filter: `blur(${Math.min(scrollY / 20, 10)}px)`,
    transition: 'background-position 0.5s ease, filter 0.5s ease',
  };

  const slideInFromLeft = "opacity-0 -translate-x-20 " + (isVisible ? "opacity-100 translate-x-0" : "");
  const slideInFromRight = "opacity-0 translate-x-20 " + (isVisible ? "opacity-100 translate-x-0" : "");
  const fadeIn = "opacity-0 " + (isVisible ? "opacity-100" : ""); 

  return (
    <div className="min-h-screen">
      <section className="w-full h-screen relative overflow-hidden text-white flex items-center justify-center">
      <div className="absolute top-4 left-4 z-10">
        <a href="https://www.instagram.com/_adamvi_/">
          <Instagram className="w-10 h-10 text-white/50" />
        </a>
      </div>
        <div className="select-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center z-10">
          <h1 className="text-5xl font-bold mb-4">
          Nice to meet you<br></br>
          I'm Adam
          </h1>
          <p className="text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl text-center whitespace-nowrap">Multimedia | Programming | Software | Robotics</p>
        </div>

        {/* Bouncing Arrow */}
        {showArrow && (
          <div 
          className={`absolute bottom-32 sm:bottom-32 md:bottom-24 lg:bottom-16 transform -translate-x-1/2 z-20 animate-bouncearrow transition-all duration-1000 ease-out 
            ${scrollY < 200 ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}
        >
            <button>
              <ArrowBigUpDash className="w-10 h-10 text-white/50" />
            </button>
          </div>
        )}

        {/* Gradient background with smooth blur and movement */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-orange-500 via-purple-600 via-indigo-500 via-purple-600 to-orange-500 animate-lava-lamp"
          style={gradientStyle}
        ></div>
      </section>

      {/* About Me Section */}
      <section
        className={`py-16 transform transition-all duration-1000 ease-out ${scrollY > 200 ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`}
        id="about"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">About me</h2>
          <ASCIIText />
          <p className="text-lg text-gray-700 max-w-3xl mx-auto text-center mt-60">
          I am a versatile freelancer specializing in multimedia, programming, software management, and robotics. I combine creativity with technical know-how to create innovative solutions.
          </p>
        </div>
      </section>

      {/* Skills Section */}
      <section className={`py-16 transform transition-all duration-1000 ease-out ${scrollY > 500 ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`} id="skills">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">My skills</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {skills.map((skill, index) => (
              <Card
                key={index}
                className={`hover:shadow-xl transition-all duration-500 transform hover:-translate-y-2 opacity-0 animate-fadeInUp`}
                style={{ animationDelay: `${index * 200}ms` }}
              >
                <CardHeader className="text-center">
                  <div className="flex justify-center">
                    {skill.icon}
                  </div>
                  <CardTitle className="text-xl font-bold hover:text-blue-600 transition-colors">
                    {skill.title}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600 text-center">{skill.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className={`py-16 transform transition-all duration-1000 ease-out ${scrollY > 900 ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'}`} id="contact">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Contact</h2>
          <div className="flex justify-center object-center">
            <a href="mailto:adam@bernar.cz" class="email-button bg-blue-600 text-white px-6 py-3 rounded-3xl hover:bg-blue-700 transition-all duration-300 hover:scale-105 transform">
            Contact me 
            </a>
          </div>
        </div>
      </section>

  {/* Footer */}
  <footer className="py-4 bg-primarydark text-[#7d7d7d]">
        <div className="container mx-auto px-4 text-center">
          <p>
          The website has been updated and deployed at {deployedAt}.
          </p>
        </div>
      </footer>
      
      <style jsx global>{`
        @keyframes lava-lamp {
          0% {
            background-position: 50% 0%;
          }
          25% {
            background-position: 50% 25%;
          }
          50% {
            background-position: 50% 40%;
          }
          75% {
            background-position: 50% 25%;
          }
          100% {
            background-position: 50% 0%;
          }
        }

        @keyframes rotate90 {
          50% {
            transform: rotate(90deg);
          }
            100% {
            transform: rotate(0deg);
          }
        }

        .animate-lava-lamp {
          animation: lava-lamp 10s linear infinite;
          background-size: 400% 400%;
        }

        @keyframes bouncearrow {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }

        .animate-bouncearrow {
          animation: bouncearrow 2s infinite;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeInUp {
          animation: fadeInUp 0.8s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default Portfolio;